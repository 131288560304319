













import { FiltroDeBuscaDeUsuarios, Loja, Perfil } from '@/models'
import { Vue, Component, PropSync } from 'vue-property-decorator'

@Component
export default class FiltrosDeUsuarios extends Vue  {
	@PropSync("value") filtro!: FiltroDeBuscaDeUsuarios

	get chips() {
		return [
			...this.filtro.perfis.map<ChipDeFiltro>(
				perfil => criarTipoDeFiltro(perfil, 'perfil'),
			),
			...this.filtro.lojas.map<ChipDeFiltro>(
				loja => criarTipoDeFiltro(loja, 'loja'),
			),
		]
	}

	remove(chip: ChipDeFiltro) {
		if(chip.tipo === 'perfil') {
			this.$emit('input', {
				...this.filtro,
				perfis: this.filtro.perfis.filter(
					perfil => perfil.id !== chip.value,
				),
			} as FiltroDeBuscaDeUsuarios)
		}
		if(chip.tipo === 'loja'){
			this.$emit('input', {
				...this.filtro,
				lojas: this.filtro.lojas.filter(
					loja => loja.id !== chip.value,
				),
			} as FiltroDeBuscaDeUsuarios)
		}
	}
}

function criarTipoDeFiltro(objeto: Perfil | Loja, tipo: ChipDeFiltro['tipo']): ChipDeFiltro {
	return {
		tipo,
		value: objeto.id,
		text: objeto.nome,
	}
}

interface ChipDeFiltro {
	tipo: 'loja' | 'perfil'
	text: string
	value: string
}

