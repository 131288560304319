






































import { Vue, Component, Ref, PropSync } from 'vue-property-decorator'
import SelecaoDePerfil from '@/components/perfil/SelecaoDePerfil.vue'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import { FiltroDeBuscaDeUsuarios } from '@/models'


@Component({
	components: {
		SelecaoDePerfil,
		SeletorDeLojas,
	},
})

export default class MenuDeFiltrosDeUsuario extends Vue {
[x: string]: any
	@PropSync('value', { default: obterFiltroDeUsuarioDefault }) filtro!: FiltroDeBuscaDeUsuarios
	@Ref() tags!: HTMLInputElement
	mostra: boolean | null = null

	obterFiltroDeUsuarioDefault = obterFiltroDeUsuarioDefault

	filtrar() {
		this.$emit('confirmar', this.filtro)
		this.mostra = false
	}
}	

export function obterFiltroDeUsuarioDefault(): FiltroDeBuscaDeUsuarios {
	return {
		perfis: [],
		lojas: [],
	}
}
