



















































import { Vue, Component, PropSync, Ref, Watch, Prop } from 'vue-property-decorator'
import { Pageable, Perfil } from '@/models'
import AlertModule from '../../store/vuex/aplicacao/AlertModule'
import { FindPerfilUseCase } from '@/usecases'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { RawLocation } from 'vue-router'
import axios, { CancelTokenSource } from 'axios'

@Component
export default class SelecaoDePerfil extends Vue  {
	@PropSync('value') perfil!: string | Perfil | null
	@Prop({ type: String }) idPerfilSalvo!: string | null
	@Ref() linkDePerfil!: HTMLAnchorElement

	perfis: Perfil[] = []
	findPerfilUseCases = new FindPerfilUseCase()

	cancelToken: CancelTokenSource | null = null
	
	busca = ''
	carregando = false
	carregouTodosOsPerfis = false
	pageable: Pageable = {
		page: 0,
		size: 10,
	}

	async created() {
		if (this.idPerfilSalvo) {
			this.perfis.push(
				await this.findPerfilUseCases.findById(this.idPerfilSalvo),
			)
		}
		this.carregar()
	}

	get podeLer() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso(
			'ler',
			'perfis',
		)
	}

	get rotaDoPerfil(): RawLocation | undefined {
		if (!this.perfil) return undefined
		return {
			name: 'Edição de Perfil',
			params: {
				id: typeof this.perfil === 'object'
					? this.perfil.id
					: this.perfil,
			},
		}
	}

	irParaPerfil() {
		if (!this.rotaDoPerfil) return
		const routeData = this.$router.resolve(this.rotaDoPerfil);
		window.open(routeData.href, '_blank');
	}

	async carregar(carregandoNovaPagina?: boolean) {
		try {
			this.carregando = true
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const paginaDePerfis = await this.findPerfilUseCases.findAll({
				page: this.busca ? this.pageable.page || -1 : this.pageable.page || 0,
				size: this.pageable.size,
				busca: !carregandoNovaPagina ? (this.busca || undefined) : undefined,
				axiosConfig,
			})
			paginaDePerfis.content.forEach(perfil => {
				this.perfis.push(perfil)
			})
			this.carregouTodosOsPerfis = paginaDePerfis?.last || false
			this.carregando = false
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError('Não foi possível obter os perfis')
		} finally {
			this.carregando = false
		}
	}

	@Watch('busca', { deep: true, immediate: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (!buscaAnterior || busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.perfis = []
		this.carregar()
	}

	async pageNext() {
		this.pageable = {
			...this.pageable,
			page: this.pageable.page
				? this.pageable.page + 1
				: 1,
		}
		await this.carregar(true)
	}

	get noDataMessage() {
		if (!this.busca && !this.carregando) {
			return 'Digite para consultar...'
		} else if (this.carregando) {
			return 'Carregando...'
		}
		return '$vuetify.noDataText'
	}
}

