import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import { Usuario, Loja, Perfil } from '@/models'
import store from '@/store/vuex'
import createSearchTags from '@/shareds/createSearchTags'

@Module({
	name: 'UsuariosStore',
	namespaced: true,
	dynamic: true,
	store,
})
export default class UsuariosStore extends VuexModule {
	busca = ''
	usuarios: Usuario[] = []
	usuarioSelecionado: Usuario | null = null
	lojasUsuarioSelecionado: Loja[] = []
	perfis: Perfil[] = []

	@Mutation
	adicionaUsuario(usuario: Usuario) {
		this.usuarios.push(usuario)
	}

	@Mutation
	atualizaUsuario(usuario: Usuario) {
		const userIndex = this.usuarios.findIndex(
			element => element.id === usuario.id,
		)
		this.usuarios.splice(userIndex, 1, usuario)
	}

	@Mutation
	setPerfis(perfis: Perfil[]) {
		this.perfis = perfis
	}

	@Mutation
	setUsuarios(usuarios: Usuario[]) {
		this.usuarios = usuarios
	}

	@Mutation
	setUsuarioSelecionado(usuario: Usuario) {
		this.lojasUsuarioSelecionado = []
		this.usuarioSelecionado = usuario
	}

	@Mutation
	setLojasUsuarioSelecionado(lojas: Loja[]) {
		this.lojasUsuarioSelecionado = lojas
	}

	@Mutation
	deletaLojaUsuario(idLoja: string) {
		if (!this.usuarioSelecionado) return

		const idUsuarioSelecionado = this.usuarioSelecionado.id
		const usuarios = this.usuarios.map(usuario => {
			if (usuario.id !== idUsuarioSelecionado) return usuario

			return {
				...usuario,
				lojas: usuario.lojas.filter(loja => loja.id !== idLoja),
			}
		})

		this.usuarios = usuarios
		this.lojasUsuarioSelecionado = this.lojasUsuarioSelecionado.filter(
			loja => loja.id !== idLoja,
		)
	}

	get lojasUsuario() {
		return this.lojasUsuarioSelecionado
	}

	get termoBusca() {
		return this.busca
	}

	get usuariosEmOrdemAlfabetica() {
		return [...this.usuarios].sort((usuarioA, usuarioB) =>
			usuarioA.nome.localeCompare(usuarioB.nome),
		)
	}

	get usuariosFiltrados() {
		const tags = createSearchTags(this.busca)
		return this.usuariosEmOrdemAlfabetica.filter(usuario =>
			tags.every(tagAPesquisar =>
				usuario.tags && usuario.tags.some(tag => tag.includes(tagAPesquisar)),
			),
		)
	}

	get usuarioAtual() {
		return this.usuarioSelecionado
	}

	get perfil() {
		return (id: string) => this.perfis.find(
			perfil => perfil.id === id,
		)
	}
}
export const UsuariosModule = getModule(UsuariosStore)
